import gql from 'graphql-tag'

export const insertionOrderDetails = gql`
  fragment insertionOrderDetails on InsertionOrderDetails {
    orgID
    insertionOrderSource
    opportunityID
    purchaseOrderNumber

    salespersonContactEmail
    adTraffickerContactEmail
    billingContactEmail
    businessContactEmail
    opsContactEmail

    creativeProductionCost
    currency
    adUnitCPM
    clientBudget
    billingSource
    invoicingSource

    brandLiftStudies {
      provider
      CPM
    }
    audienceSegments {
      provider
      CPM
      name
      code
    }
    thirdPartySettings {
      provider
      impressionsCount
    }
    billingSettings {
      grossCPM
      grossBudget
      cutRate
      discount
      totalImpressions
      grossIOCPM

      netCPM
      netAmount
      totalCost

      finalImpressions
      totalAmount
      notes
    }
  }
`
